@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .nice-trans{
    @apply transition-all duration-300 ease-in-out;
  }

  .col-flex-center{
    @apply flex flex-col items-center;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-apple-white{
  background-color: #F5F5F7;
}

.text-apple-white{
  color: #F5F5F7;
}

.text-apple-black{
  color: #1D1D1F;
}

.bg-apple-black{
  background-color: #1D1D1F;
}

.bg-apple-space-gray{
  background-color: #333336;
}

.bg-apple-space-black{
  background-color: #000000;
}

.bg-apple-gray{
  background-color: #F5F5F7;
}

.bg-apple-blue{
  background-color: #52a6fa;
}

.text-apple-light-gradient{
  background: -webkit-linear-gradient(#e3e3eb, #a5a5aa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-apple-gray-gradient{
  background: -webkit-linear-gradient(#6E6E73, #39393b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-apple-black-gradient{
  background: -webkit-linear-gradient(#414144, #1D1D1F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-apple-white-gradient{
  background: -webkit-linear-gradient(#F5F5F7, #ebebf1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-apple-gray{
  color: #6E6E73;
}

.font-nono{
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", sans-serif;
}

.font-DM-Sans{
  font-family: "DM Sans", sans-serif;
}

.fixed-width {
  width: 200px; /* Adjust the width as needed */
}

.bg-glass{
  backdrop-filter: blur(25px);
  background-color: rgba(255, 255, 255, 0.014);
}

.bg-glass-sm{
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.014);
}

.hoverContent{
  top: 100%;
  transition: top 500ms ease-in-out;
}

.default-view:hover .hoverContent{
  top: 0;
}

.hoveringNav{
  transition: all 250ms ease-in-out;
}

.hoveringNav:hover{
  padding-top: 2px;
}

.font-clamp{
  font-size: clamp(16px, 1.4vw, 36px);
}